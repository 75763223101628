<template>
    <div class="convo-stream w-100">
        <div class="flex m-b-2">
            <Tags
                class="channel-tags m-r-2 w-75"
                placeholder="Type to search channels..."
                label="Channel(s)"
                :value="data.channels"
                :errors="errors.channels"
                :autocomplete-items="channelOptions"
                :only-autocomplete="true"
                :separators="[' ', ';']"
                :max-tags="5"
                :has-margin-bottom="false"
                @autocomplete="onChannelAutocomplete"
                @input="onInput('channels', $event)"
            />
            <Select
                :value="data.sortOrder"
                :errors="errors.sortOrder"
                name="sort"
                label="Sort Order"
                :options="sortOrderOptions"
                :disabled="false"
                class="sort-order w-25"
                :has-margin-bottom="false"
                @input="onInput('sortOrder', $event)"
            />
        </div>

        <div class="flex m-b-2">
            <CheckboxGroup
                label="Convo Types(s)"
                :options="convoTypeOptions"
                horizontal-styled
                show-label
                :selected-options="convoTypes"
                :errors="errors.convoTypes"
                :has-margin-bottom="false"
                class="convo-types m-r-2 w-75"
                @input="(types) => onNestedInput('convoTypes', types, 'id')"
            />
        </div>

        <div class="flex m-b-2">
            <Tags
                class="convo-tags m-r-2 w-75"
                placeholder="Type to search convo tags..."
                label="Convo Tag(s)"
                :value="data.convoTags"
                :errors="errors.convoTags"
                :autocomplete-items="convoTagOptions"
                :only-autocomplete="true"
                :separators="[' ', ';']"
                :disabled="false"
                :max-tags="5"
                :has-margin-bottom="false"
                @autocomplete="onTagAutocomplete"
                @input="onInput('convoTags', $event)"
            />
            <NumberInput
                id="convo-stream-convo-length"
                name="convo-stream-convo-length"
                :max="10"
                :value="data.convoLength"
                :errors="errors.convoLength"
                class="number-input w-25"
                label="Number of Convos"
                :disabled="false"
                :has-margin-bottom="false"
                @input="onInput('convoLength', $event)"
            />
        </div>

        <div class="flex">
            <CheckboxGroup
                label="Chat Group"
                :options="chatGroupOptions"
                horizontal-styled
                show-label
                has-margin-bottom
                :selected-options="chatGroups"
                :errors="errors.chatGroup"
                class="chat-group m-r-2 w-75"
                @input="(groups) => onNestedInput('chatGroup', groups, 'id')"
            />
        </div>
    </div>
</template>
<script>
import { sortOrderOptions, chatGroupOptions, convoTypeOptions } from '@/json';
import { Tags, Select, CheckboxGroup, NumberInput } from '@/components/forms';
import ContentDiscoveryMixin from '@/mixins/contentDiscoveryMixin';

const nestMap = (list, key) => list.map((item) => ({ [key]: item }));

export default {
    name: 'ContentDiscoveryConvoStream',
    components: {
        Tags,
        Select,
        CheckboxGroup,
        NumberInput,
    },
    mixins: [ContentDiscoveryMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            sortOrderOptions,
            chatGroupOptions,
            convoTypeOptions,
            convoTagOptions: [],
            channelOptions: [],
        };
    },
    computed: {
        convoTypes() {
            const { convoTypes } = this.data;
            return convoTypes ? nestMap(convoTypes, 'id') : [];
        },
        chatGroups() {
            const { chatGroup } = this.data;
            return chatGroup ? nestMap(chatGroup, 'id') : [];
        },
    },
    methods: {
        onNestedInput(propName, list, key) {
            this.onInput(
                propName,
                list.flatMap((item) => item[key]),
            );
        },
        async onTagAutocomplete(value) {
            const tags = await this.$api.tag.fetch({ type: 'convo', query: value });
            if (tags) {
                this.convoTagOptions = tags;
            }
        },
        async onChannelAutocomplete(query) {
            const channels = await this.$api.channel.getChannels({
                q: query,
                page: 1,
                size: 10,
            });
            if (channels?.items?.length) {
                this.channelOptions = channels.items.map((item) => ({
                    id: item.code,
                    name: item.name,
                }));
            }
        },
    },
};
</script>
